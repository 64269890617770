@import '~antd/dist/antd.less';

body {
  background: #364150;
}

.map-page-container {
  position: relative;
  height: 800px;
}

.map-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font: 15px/24px monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.logo-login {
  height: 60px;
  margin: 40px auto;
  text-align: center;
}

.login {
  background: #364150;
}

.form-login {
  background:#ECEEF1;
  padding: 20px;
  margin: 40px auto;
  width: 350px;
  text-align: center;
}

.logoImgLogin{
  height: 80px;
  margin: auto;
}

.h2-login {
  color: #4FB879;
  font-weight: 400;
  font-size: 30px;
}

.logo {
  height: 36px;
  margin: 16px;
}
  
.logoImg  {
  height:37px;
  text-align: center;
  margin-left: 13px;
}

.avatar {
  text-align: right;
  margin-right: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.logoutButton {
  margin: 5px 5px;
  padding: 5px auto;
  border: black 1px;
  background-color: #CCCCCC;
}

.site-layout .site-layout-background {
  background: #fff;
}

.button-add {
  position: absolute;
  right: 40px;
  top : 160px;
}

.filter-title{
  width: 160px;
  margin-bottom: 18px;
}

.filter{
  width: 300px;
  margin-bottom: 18px;
}
@primary-color: #2FAC66;@link-color: #2FAC66;